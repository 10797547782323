<template>
  <div class="footer-container">
    <div class="other--info__container">
      <div class="other--topic">
        <div class="other--topic__left">
          <ul class="topic-item first">
            <li
              class="topic-item__desc-first home topic-item__desc-hover"
              @click="jump('/')"
            >
              首页
            </li>
            <li
              class="topic-item__desc topic-item__desc-hover"
              @click="jump('/product-service')"
            >
              交易平台
            </li>
            <!-- TODO: -->
            <li
              class="topic-item__desc topic-item__desc-hover"
              @click="jump('/shareholder-introduction')"
            >
              会员服务
            </li>
            <li
              class="topic-item__desc topic-item__desc-hover"
              @click="jump('/news')"
            >
              新闻资讯
            </li>
          </ul>
          <ul class="topic-item second">
            <li class="topic-item__desc-first">友情链接</li>
            <li
              class="topic-item__desc topic-item__desc-hover"
              @click="jumpOut('http://www.600633.cn/')"
            >
              浙报数字文化集团股份有限公司
            </li>
            <li
              class="topic-item__desc topic-item__desc-hover"
              @click="jumpOut('https://www.zjfh.cn/')"
            >
              浙江省金融控股有限公司
            </li>
            <li
              class="topic-item__desc topic-item__desc-hover"
              @click="jumpOut('https://www.dbappsecurity.com.cn/')"
            >
              杭州安恒信息技术股份有限公司
            </li>
          </ul>
          <ul class="topic-item third">
            <li class="topic-item__desc-first">关于我们</li>
            <li
              class="topic-item__desc topic-item__desc-hover"
              @click="jump('/about-us')"
            >
              公司简介
            </li>
            <li
              class="topic-item__desc topic-item__desc-hover"
              @click="jumpOut('https://www.liepin.com/company/12190597/')"
            >
              加入我们
            </li>
          </ul>
          <ul class="topic-item contact">
            <li class="topic-item__desc-first">联系方式</li>
            <li class="topic-item__desc">浙江大数据交易中心有限公司</li>
            <li class="topic-item__desc">杭州市滨江区缤纷街615号自贸大厦6层</li>
            <li class="topic-item__desc">
              <span class="tel">0571-56132050</span>
              <span>工作日（9:00～17:30）</span>
            </li>
          </ul>
        </div>
        <div class="other--topic__right">
          <p class="right--title">关注公众号</p>
          <div class="data--tech__code"></div>
        </div>
      </div>
      <div class="copyright--container">
        <span>浙江大数据交易中心有限公司 版权所有©2016-2023</span>
        <span class="copyright-number" @click.stop="checkBeiAn"
          >浙ICP备20000757号-1</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {
    jump(params) {
      this.$router.push({ path: params });
    },
    jumpOut(url) {
      let win = window.open(url, "_blank");
      win.opener = null;
    },
    checkBeiAn() {
      let url = "https://beian.miit.gov.cn/#/Integrated/index";
      let win = window.open(url, "_blank");
      win.opener = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  background: #000000;
  width: 100%;
  justify-content: center;
  .other--info__container {
    box-sizing: border-box;
    width: 1280px;
    margin: 0 auto;
    padding: 50px 0 0 0;

    .other--topic {
      display: flex;
      padding-bottom: 101px;
      justify-content: space-between;
    }

    .other--topic__left {
      display: flex;
      box-sizing: border-box;

      .topic-item.first,
      .topic-item.second {
        padding-right: 80px;
      }

      .topic-item.third {
        padding-right: 80px;
      }

      .topic-item {
        .topic-item__desc-first {
          text-align: left;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 25px;
          margin-bottom: 30px;
        }

        .topic-item__desc-first.home {
          cursor: pointer;
        }
        .topic-item__desc-hover:hover {
          color: #007ef2;
        }
        .topic-item__desc {
          text-align: left;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #cccccc;
          line-height: 20px;
          margin-bottom: 22px;

          .tel {
            font-size: 22px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #ffffff;
            margin-right: 20px;
          }
        }

        .topic-item__desc:last-child {
          margin-bottom: 0;
        }
      }

      .topic-item.contact {
        .topic-item__desc {
          // cursor:;
          cursor: auto;
        }
      }
    }

    .other--topic__right {
      .right--title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 30px;
      }

      .data--tech__code {
        width: 110px;
        height: 110px;
        background: url("../assets/img/qr-code.png") center center/cover;
      }
    }

    .copyright--container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      height: 80px;
      background: #000000;
      box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
      .copyright-number {
        cursor: pointer;
      }
      .copyright-number:hover {
        color: #007ef2;
      }
    }
  }
}
</style>
